import styled from '@emotion/styled';
import { fadeIn } from '../../utils/styles/animations';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation-duration: 0.75s;
  animation-name: ${fadeIn};
  margin: 20px 20px;
`;

export default ContentWrapper;
