import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { ContentWrapper, SubHeader, Paragraph } from '../Layout';
import * as strings from '../../strings';

const SectionWrapper = styled.div`
  margin-top: 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 411px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

type AboutProps = {} & RouteComponentProps;

const About: React.FC<AboutProps> = () => {
  const title = `${strings.en.about} ${strings.en.title}`;

  return (
    <ContentWrapper>
      <SubHeader title={title} />
      <SectionWrapper>
        {strings.en.aboutText.map(txt => {
          return <Paragraph text={txt} />;
        })}
      </SectionWrapper>
    </ContentWrapper>
  );
};

export default About;
