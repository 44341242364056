import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';

const PStyled = styled.p`
  text-align: justify;
  text-indent: 2rem;
  margin-top: 0;
`;

type ParagraphProps = {
  text: string;
} & RouteComponentProps;

const Paragraph: React.FC<ParagraphProps> = props => {
  const { text } = props;

  return <PStyled>{text}</PStyled>;
};

export default Paragraph;
