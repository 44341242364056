import React from 'react';
import { globalHistory as history, Link } from '@reach/router';
import styled from '@emotion/styled';
import theme from '../../theme';
import * as strings from '../../strings';

const NavItem = styled(Link)`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 0.95rem;
  text-transform: lowercase;
  font-weight: 400;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: ${theme.color.primary};
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${theme.color.tertiary};
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0 0;
    margin-bottom: 20px;
    font-size: 1.25rem;
    z-index: 6;
    width: 80%;
  }
`;

const StyledLink = styled(({ isCurrent, ...rest }) => <NavItem {...rest} />)(
  ({ isCurrent }) => ({
    color: isCurrent ? theme.color.gray.lightest : theme.color.text.heading,
    ':hover': {
      color: isCurrent ? theme.color.gray.lightest : theme.color.gray.lightest,
    },
    ':after': {
      background: theme.color.gray.lightest,
      width: isCurrent ? '100%' : '0',
      transition: isCurrent ? 'none' : 'all 0.4s ease-in',
    },
  })
);

const routes = [
  {
    title: strings.en.about,
    path: '/about',
  },
  {
    title: strings.en.contact,
    path: '/contact',
  },
];

type NavbarLinkProps = {
  isOpen: boolean;
  setMenuState: (state: boolean) => void;
};

const NavbarLinks: React.FC<NavbarLinkProps> = props => {
  const { isOpen, setMenuState } = props;
  const { location } = history;
  const { pathname } = location;

  return (
    <>
      {routes.map(route => {
        return (
          <StyledLink
            key={route.title}
            to={route.path}
            isCurrent={pathname === route.path}
            onClick={() => {
              if (isOpen) {
                setMenuState(false);
              }
            }}
          >
            {route.title}
          </StyledLink>
        );
      })}
    </>
  );
};

export default NavbarLinks;
