import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
// import theme from '../../theme';
import { ReactComponent as LogoIcon } from '~/icons/logo.svg';
import { ReactComponent as TitleIcon } from '~/icons/title-only.svg';

const iconStyles = css`
  width: 30px;

  @media (max-width: 768px) {
    width: 40px;
    display: flex;
  }
`;

const iconTitleStyles = css`
  width: 150px;
  margin: 0 0 0 16px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoWrap = styled(Link)`
  margin: auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type LogoProps = {
  isOpen: boolean;
  setMenuState: (state: boolean) => void;
};

const Logo: React.FC<LogoProps> = props => {
  const { isOpen, setMenuState } = props;

  return (
    <LogoWrap
      to="/"
      onClick={() => {
        if (isOpen) {
          setMenuState(false);
        }
      }}
    >
      <LogoIcon css={iconStyles} />
      <TitleIcon css={iconTitleStyles} />
    </LogoWrap>
  );
};

export default Logo;
