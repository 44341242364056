/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import GlobalStyles from './GlobalStyles';
import Navbar from '../Navbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 30px 20px;
  width: 100%;
  max-width: 1400px;

  @media (max-width: 768px) {
    padding: 90px 18px 20px;
  }
`;

type Props = {};

const DefaultLayout: React.FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <GlobalStyles />
      <div
        css={css`
          overflow: hidden;
          display: flex;
          justify-content: center;
        `}
        {...props}
      >
        <Navbar />
        <Container>{children}</Container>
      </div>
    </>
  );
};

export default DefaultLayout;
